/* src/components/Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content p {
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons svg {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.social-icons svg:hover {
  color: #ff7e5f;
}
