.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.home-hero {
    width: 100%;
    height: 400px;
    background-image: url('../assets/images/your-image.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
}

.home-services {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.home-service {
    flex: 1;
    margin: 10px;
    padding: 20px;
    background-color: #f4f4f4;
    text-align: center;
}
