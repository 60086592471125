/* src/components/About.css */
.about-page {
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f4f4f9;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

.about-intro, .company-mission, .our-values, .our-team {
    margin-bottom: 40px;
    animation: fadeInUp 1s ease-in-out;
}

.about-intro h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
    border-bottom: 3px solid #ff7e5f;
    display: inline-block;
}

.about-intro p {
    font-size: 1.2rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    color: #555;
}

.company-mission h2, .our-values h2, .our-team h2 {
    font-size: 2.5rem;
    color: #ff7e5f;
    margin-bottom: 20px;
    position: relative;
}

.company-mission h2::after, .our-values h2::after, .our-team h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 3px;
    width: 50px;
    background-color: #ff7e5f;
}

.company-mission p, .our-team p {
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    color: #555;
}

.our-values {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.values-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.value-item {
    flex: 1 1 calc(50% - 20px);
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
}

.value-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.value-item h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
}

.value-item p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #666;
}

.our-team p {
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    color: #555;
}

@media (max-width: 768px) {
    .values-container {
        flex-direction: column;
    }

    .value-item {
        flex: 1 1 100%;
    }
}

/* Keyframes for animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
