/* src/components/Testimonials.css */
.testimonials {
  background-color: #fff;
  padding: 40px 0;
  text-align: center;
}

.testimonials h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.testimonial {
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.testimonial p {
  font-style: italic;
}

.testimonial span {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}
