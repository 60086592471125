/* Navbar.css */

.navbar {
  width: 100%;
  background: linear-gradient(90deg, #ff7e5f 0%, #f0610f 100%); /* Smoother gradient from light coral to deeper coral */
  color: white;
  padding: 10px 0; /* Increased padding for better spacing */
  position: relative; /* Fixed position to keep navbar visible during scroll */
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s ease; /* Smooth transition for background changes */
}

.navbar.scrolled {
  background-color: #f0610f; /* Solid color background on scroll */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo a {
  font-size: 2rem; /* Slightly larger font for logo */
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links ul li {
  margin: 0 20px; /* Increased spacing between items */
}

.nav-links ul li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: color 0.3s, transform 0.3s; /* Added smooth transition for hover effects */
}

.nav-links ul li a:hover {
  color: #ffdd57; /* Change color to a bright yellow on hover */
  transform: scale(1.1); /* Slightly enlarge text on hover */
}

.primary-btn {
  padding: 10px 20px;
  background-color: #00c8ff; /* Bright and engaging button color */
  color: white;
  border-radius: 25px; /* Rounded button corners */
  text-decoration: none;
  margin-left: 15px;
  transition: background-color 0.3s, transform 0.3s;
}

.primary-btn:hover {
  background-color: #ff7e5f; /* Change to coral on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 30px; /* Slightly larger bars for better visibility */
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: all 0.3s ease;
}

/* Change hamburger bar appearance when open */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .nav-links-display-none{
    display: none;
  }
  .nav-links {
    position: absolute;
    top: 60px;
  
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Slightly transparent dark background */
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links ul {
    flex-direction: column;
  }

  .nav-links ul li {
    margin: 20px 0;
  }

  .primary-btn {
    margin-top: 20px;
  }

  .hamburger {
    display: flex;
  }
}
