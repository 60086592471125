/* src/components/ContactForm.css */

.contact-form {
  padding: 50px 0;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 700px;
  margin: 50px auto;
  animation: fadeInUp 1s ease-in-out;
}

.contact-form h2 {
  margin-bottom: 30px;
  font-size: 2rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #ff7e5f;
  outline: none;
  background-color: #fff;
}

.contact-form button {
  background-color: #ff7e5f;
  background-image: linear-gradient(to right, #ff7e5f, #feb47b);
  border: none;
  padding: 12px 30px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form button:hover {
  background-image: linear-gradient(to right, #feb47b, #3c93da);
  transform: translateY(-2px);
}

.contact-form button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add this media query at the end */
@media (max-width: 768px) {
  .contact-form form {
    width: 90%;
  }

  .contact-form {
    padding: 30px 15px;
    margin: 30px auto;
  }

  .contact-form h2 {
    font-size: 1.5rem;
  }
}
