/* src/components/Header.css */
.header {
  height: 90vh; /* Full viewport height */
  padding-top: 90px; /* Adjust based on your navbar height */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url('../assets/images/temp.jpg') no-repeat center center;
  background-size: cover; /* Ensure the image covers the entire header */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative; /* Ensure overlay positioning works correctly */
  animation: fadeInUp 1s ease-in-out;
}

.header h1 {
  font-size: 4rem; /* Increase the font size for a bigger impact */
  margin-bottom: 20px;
}

.header p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.primary-btn {
  background-color: #ff7f50; /* Button color */
  color: white;
  padding: 15px 30px;
  font-size: 1.25rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #ff4500; /* Darker shade on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity for better visibility */
  z-index: 0; /* Ensure it is behind the content */
}

.header-content {
  position: relative;
  text-align: center;
  z-index: 1; /* Ensure content is above the overlay */
}

.header-content h1 {
  font-size: 2.5rem; /* Adjusted for consistency */
}

.header-content p {
  margin: 10px 0;
}

.cta-button {
  display: inline-block; /* Ensure it behaves like a button */
  background-color: #ff7e5f;
  background-image: linear-gradient(to right, #ff7e5f, #feb47b);
  border: none;
  padding: 12px 24px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  transition: background-image 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-image: linear-gradient(to right, #feb47b, #4c7bd8);
  transform: translateY(-2px);
}

.cta-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .header p {
    font-size: 1.2rem;
  }

  .primary-btn {
    padding: 12px 24px;
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
