.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  background-color: #f8f8f8;
  animation: fadeInUp 1s ease-in-out;
}

.services-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.service {
  text-align: center;
  width: 30%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.section {
  padding-top: 60px; /* Adjust based on the height of your navbar */
  margin-top: -60px; /* Compensates for the padding so the section appears at the correct location */
  scroll-margin-top: 60px; /* Ensures proper scroll position */
}

.service-icon {
  font-size: 3.5rem;
  color: #ff7e5f;
  margin-bottom: 10px;
}

.service h3 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
}

.service p {
  font-size: 1rem;
  color: #666;
}

.service:hover {
  transform: translateY(-5px);
}

/* Responsive design */
@media (max-width: 768px) {
  .services-row {
    flex-direction: column;
  }

  .service {
    width: 90%;
    margin: 10px auto;
  }
}
